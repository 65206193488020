/* @import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap"); */

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .hoverEffect {
    @apply duration-300 cursor-pointer;
  }
  .headerSideNavLi {
    @apply hover:underline underline-offset-2 cursor-pointer text-bsmBG hover:text-white;
  }
}

/* ============= Container ============== */
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  min-height: 100vh; /* Ensures the container won't collapse */
}

@media (max-width: 1024px) {
  .container {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .container {
    width: 100%;
  }
}

/* ============= NavLink active Start here ============== */
nav .active li {
  color: black;
  font-weight: 600;
  text-decoration: 1px underline black;
}

@media screen and (max-width: 665px) {
  nav .active li {
    color: white;
    font-weight: 600;
    text-decoration: 1px underline white;
  }
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  height: 6px;
  background-color: #ECECEC;
}

::-webkit-scrollbar-track-piece {
  background-color: #ECECEC;
}

::-webkit-scrollbar-thumb {
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, .05);
  background: linear-gradient(to right, rgba(200, 200, 200, .04)0, rgba(0, 0, 0, .04)100%), url(https://idata.mmvietnam.com/images/scrollbar_thumb_bg.png) no-repeat center #6B6B6B;
  border-radius: 3px;
}